import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import Container from "./container"
import styled from "@emotion/styled"

const Header = () => {
  const { logo } = useStaticQuery(logoQuery)
  return (
    <StyledHeader>
      <Container>
        <Link to="/">
          <Img
            className="logo"
            fluid={logo.childImageSharp.fluid}
            alt="raum3 GmbH"
          />
        </Link>
      </Container>
    </StyledHeader>
  )
}

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--headerHeight);
  background-color: var(--accentColor);
  color: var(--accentColorDefault);
  .logo {
    width: 150px;
    filter: var(--accentColorLogoFilter);
  }
`

const logoQuery = graphql`
  {
    logo: file(sourceInstanceName: { eq: "images" }, name: { eq: "logo" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default Header
