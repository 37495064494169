import React from "react"
import { css, Global } from "@emotion/react"
import normalize from "emotion-normalize"

const globalCSS = css`
  :root {
    /* Font Families */
    --displayFont: "Fira Sans", sans-serif;
    --bodyFont: "Montserrat", sans-serif;

    /* Colors */
    --anthracite: #212931;
    --indigo: #6366f1;
    --teal: #14b8a6;
    --blue: #3b82f6;
    --purple: #8b5cf6;
    --darkPurple: #390f64;
    --grey: #7e7c75;
    --brown: #645858;
    --occer: #989d90;
    --lightGreen: #edefe5;
    --anthraciteShade: #171d22;
    --anthraciteTint: #2f4050;
    --white: #fff;
    --black: #111;
    --lightGrey: #fefefe;

    /* logoFilter */
    --light: none;
    --dark: invert(1);

    /* Color Roles */
    --accentColor: var(--anthracite);
    --accentColorContrast: var(--white);
    --accentColorLogoFilter: var(--light);
    --textColor: var(--black);
    --bodyBackground: var(--lightGrey);

    /* Font Sizing */
    --fontSizeH1: 3.052rem;
    --fontSizeH2: 2.441rem;
    --fontSizeH3: 1.953rem;
    --fontSizeH4: 1.563rem;
    --fontSizeH5: 1.25rem;
    --fontSizeSmall: 0.8rem;

    /* Misc */
    --borderRadius: 5px;
    --headerHeight: 100px;
  }
  ${normalize}

  html {
    font-size: 80%;
    box-sizing: border-box;
  } /*16px*/

  html *,
  html *::before,
  html *::after {
    box-sizing: inherit;
  }

  body {
    background: var(--bodyBackground);
    font-family: var(--bodyFont);
    font-weight: 400;
    line-height: 1.75;
    color: var(--textColor);
  }

  p {
    margin-bottom: 1rem;
    max-width: 40em;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 3rem 0 1.38rem;
    font-family: var(---displayFont);
    font-weight: 400;
    line-height: 1.3;
  }

  h1 {
    margin-top: 0;
    font-size: var(--fontSizeH1);
  }

  h2 {
    font-size: var(--fontSizeH2);
  }

  h3 {
    font-size: var(--fontSizeH3);
  }

  h4 {
    font-size: var(--fontSizeH4);
  }

  h5 {
    font-size: var(--fontSizeH5);
  }

  small,
  .text_small {
    font-size: var(--fontSizeSmall);
  }

  button {
    appearance: none;
    border: none;
    background-color: var(--accentColor);
    color: var(--accentColorContrast);
    padding: 0.5em 2em;
    border-radius: var(--borderRadius);
  }

  a {
    color: var(---textColor);
    text-decoration: none;
    &:hover {
      border-bottom: 1px solid var(--textColor);
    }
  }

  ::selection {
    background-color: var(--accentColor);
    color: var(--accentColorContrast);
  }

  @media screen and (min-width: 600px) {
    html {
      font-size: 100%;
    }
  }
`

const GlobalStyles = () => <Global styles={globalCSS} />

export default GlobalStyles
