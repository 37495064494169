import React from "react"
import { Link } from "gatsby"
import Container from "../components/container"
import styled from "@emotion/styled"

const Footer = () => {
  return (
    <StyledFooter>
      <Container>
        <p>&copy; {new Date().getFullYear()} raum3 GmbH</p>
        <Link to="/impressum">Impressum</Link>
      </Container>
    </StyledFooter>
  )
}

const StyledFooter = styled.footer`
  --textColor: var(--accentColorContrast);
  height: var(--headerHeight);
  align-items: center;
  background-color: var(--accentColor);
  color: var(--textColor);
  font-size: var(--fontSizeSmall);
  padding: 3rem 0;
  ${Container} {
    display: flex;
    justify-content: space-between;
    > * {
      margin: 0;
    }
  }
`

export default Footer
